<template>
  <div class="panel">
    <div class="panel_header tw-flex tw-justify-between tw-items-center">
      <page-header title="成员列表" />
    </div>
    <div class="panel_op">
      <el-input
        v-model="keywords"
        size="small"
        placeholder="请输入成员名称或账号搜索"
        style="width:300px;"
        @keyup.native.enter="onSearch">
      </el-input>
    </div>
    <div class="panel_table">
      <el-table
        :data="tableData"
        :header-cell-style="headerCellStyle"
        @row-click="rowClick"
        :loading="loading">
        <el-table-column
          label="序号"
          type="index"
          :index="indexMethod">
        </el-table-column>
        <el-table-column
          prop="username"
          label="姓名">
        </el-table-column>
        <el-table-column
          prop="mobile"
          label="账号">
        </el-table-column>
        <el-table-column
          prop="contactEmail"
          label="邮箱">
        </el-table-column>
        <el-table-column
          prop="gender"
          label="性别">
          <template slot-scope="scope">
            {{ { 0: '未知', 1: '男', 2: '女' }[scope.row.gender] }}
          </template>
        </el-table-column>
        <el-table-column label="角色">
          <template slot-scope="scope">
            {{
              scope.row.organizationRole === 1 ? '机构管理员' : (scope.row.role === globalVar.USER_ROLE_2 ? '挂靠服务集成商' : '成员')
            }}
          </template>
        </el-table-column>
        <el-table-column
          width="200"
          label="操作">
          <template slot-scope="scope" v-if="scope.row.organizationRole === 0">
            <el-button
              v-if="scope.row.role === globalVar.USER_ROLE_1"
              size="small"
              type="primary"
              @click.stop="onTransfer(scope.row)">
              管理员转让
            </el-button>
            <el-button
              size="small"
              type="danger"
              plain
              @click.stop="onDelete(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="panel_pagination tw-flex tw-justify-center tw-items-center">
      <el-pagination
        hide-on-single-page
        background
        @current-change="pageNumChange"
        :current-page.sync="pagination.pageNum"
        :page-size="pagination.pageSize"
        layout="prev, pager, next, jumper"
        :total="pagination.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'
import cookie from '@/configs/cookie'

export default {
  data () {
    return {
      loading: false,
      keywords: '',
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      tableData: []
    }
  },
  computed: {
    headerCellStyle () {
      return {
        backgroundColor: '#F7F8F9',
        color: '#909399',
        fontWeight: 'bold'
      }
    }
  },
  mounted () {
    this.getTableData(1, this.keywords)
  },
  methods: {
    indexMethod (index) {
      return this.pagination.pageSize * (this.pagination.pageNum - 1) + index + 1
    },
    rowClick (row, column, event) {
      const { href } = this.$router.resolve({
        path: `/member-detail/${row.id}`
      })

      window.open(href, '_blank')
    },
    onSearch () {
      this.getTableData(1, this.keywords)
    },
    getTableData (pageNum, keywords) {
      this.loading = true
      api.getOrgGkList({
        pageNum,
        pageSize: this.pagination.pageSize,
        keywords
      }).then(res => {
        if (res.data.code === 0) {
          this.pagination.pageNum = res.data.data.currentPage
          this.pagination.total = res.data.data.total
          this.tableData = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.loading = false
      })
    },
    onTransfer (row) {
      this.$msgbox({
        title: '转让提醒',
        message: '管理员权限转让后，您将成为机构普通成员',
        showCancelButton: true,
        confirmButtonText: '确认转让',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            api.orgTransfer(row.id).then(res => {
              if (res.data.code === 0) {
                this.$message.success('操作成功，请重新登录')
                this.$store.commit('setUserInfo', {})
                cookie.remove(this.globalVar.WEB_TOKEN)
                this.$router.replace('/login')
              } else {
                this.$message.error(res.data.message)
              }
            }).catch(err => {
              console.log(err)
              this.$message.error('请求出错')
            }).finally(() => {
              instance.confirmButtonLoading = false
              done()
            })
          } else {
            done()
          }
        }
      })
    },
    onDelete (row) {
      this.$msgbox({
        title: '删除提醒',
        message: '用户被删除后，将成为普通用户，无法继续发布服务产品',
        showCancelButton: true,
        confirmButtonText: '确认删除',
        type: 'warning',
        beforeClose: (action, instance, done) => {
          console.log(action, instance, done)
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            api.removeGkFromOrg(row.id).then(res => {
              if (res.data.code === 0) {
                this.$message.success('操作成功')
                this.getTableData(this.pagination.pageNum, this.keywords)
              } else {
                this.$message.error(res.data.message)
              }
            }).catch(err => {
              console.log(err)
              this.$message.error('请求出错')
            }).finally(() => {
              instance.confirmButtonLoading = false
              done()
            })
          } else {
            done()
          }
        }
      })
    },
    pageNumChange (page) {
      this.getTableData(page)
    }
  }
}
</script>

<style lang="scss" scoped>
.panel {
  width: 100%;
  min-width: 1030px;
  min-height: 100%;
  padding: 0 30px;
  background: #FFFFFF;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  overflow: auto;
  &_header {
    padding: 20px 0;
  }
  &_op {
    padding: 10px 0;
  }
  &_table {
    padding: 10px 0;
  }
  &_pagination {
    margin: 20px 0;
    height: 40px;
  }
}
</style>
